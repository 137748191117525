<template>
    <div class="news">
        <a href="javascript:void(0);" class="scrollT" ref="news"></a>
        <div class="sub-banner" v-if="bannerList.length > 0" :style="{'backgroundImage':'url('+ bannerList[0].pic +')'}">
			<div class="container">
				<div class="main">
					<h2 class="wow fadeInDown">News</h2>
				</div>
			</div>
		</div>
        <div class="mainbody new-main">
            <div class="container">
                <ul class="news-list">
                    <li class="wow fadeInUp"
                        v-for="item in newsList"
                        :key="item.id"
                        >
                        <router-link :to="`/newsdet?id=${item.id}`">
                        <div class="pic">
                            <div class="imgBg motion" :style="{'backgroundImage':'url('+item.pic+')'}" ></div>
                        </div>
                        <div class="main">
                            <div class="name">{{ item.title }}</div>
                            <div class="date">{{ item.add_date }}</div>
                            <div class="txt" v-html="item.detail.substr(0,200)+'...'"></div>
                            <div class="more">Read More<i></i></div>
                        </div>
                    </router-link></li>
                </ul>
                <el-pagination class="wow fadeInRight"
                    v-if="total >= 4"
                    layout="prev, pager, next"
                    :page-size="conditionObj.rows"
                    :current-page="conditionObj.p"
                    :total="total"
                    @current-change="changePage">
                </el-pagination>
            </div>
        </div>
    </div>
</template>

<script>
    import Vue from 'vue'
    import { Pagination } from 'element-ui'
    Vue.use( Pagination )
    import { WOW } from 'wowjs'
    import { getNewsList, getBannerList } from '../../request/api'
    
    export default {
        name:'News',
        metaInfo:{
            title:"DMEGC Solar-news",
            meta: [{                 // set meta
                name: 'keyWords',
                content: 'DMEGC Solar'
            },{
                name:'description',
                content:'DMEGC Solar'
            }]
        },
        props: [ 'p' ],
        data(){
            return {
                conditionObj:{
                    rows:4,
                    p:1,
                    rmd:''
                },
                total:0,
                newsList:[],
                bannerList:[]
            }
        },
        mounted(){

            getBannerList({cate:'news'}).then( res => {

                this.bannerList = res.data
            })

            this.showNewsData()
        },
        watch:{
            $route:function(){

                this.showNewsData()
            }
        },
        methods:{
            showNewsData(){

                if(this.p === undefined){
                    
                    this.conditionObj.p = 1
                }else{

                    this.conditionObj.p = parseInt(this.p)
                }

                if(this.rmd != undefined) {

                    this.conditionObj.rmd = this.rmd;
                }

                getNewsList( this.conditionObj ).then( res => {

                    this.newsList = res.data.newsList
                    this.total = parseInt( res.data.totals )
                    this.$nextTick(() => {

                        const wow = new WOW({
                            animateClass: 'animated',
                            offset: 100
                        })
                        wow.init();
                        this.$refs.news.scrollIntoView();
                    })
                })
            },
            changePage( val ){

                if(this.conditionObj.rmd != '') {

                    this.$router.push({
                        name: 'news',
                        query:{
                            p:val,
                            rmd: this.conditionObj.rmd
                        }
                    })

                } else {

                    this.$router.push({
                        name: 'news',
                        query:{
                            p:val
                        }
                    })
                }
            }
        }        
    }
</script>

<style lang="scss" scoped>
.news{
    position: relative;
}
.new-main{
    padding: 4.1667vw 0 3.9063vw;
    .news-list{
        li{
            margin-bottom: 2.0833vw;
            border-bottom: 1px solid #CCD2DF;
            padding-bottom: 2.0833vw;
        }
        a{
            display: flex;
            justify-content: space-between;
        }
        .pic{
            width: 23.9583vw;
            height: 15.625vw;
            overflow: hidden;
            .imgBg{
                height: 15.625vw;
            }
        }
        .main{
            width: 54.1667vw;
            height: 15.625vw;
            position: relative;
            .name{
                font-size: 1.5625vw;
                line-height: 1.7708vw;
                color: rgba(0,0,0,0.8);
            }
            .date{
                font-size: 0.8333vw;
                line-height: 1.0417vw;
                margin-top: 1.0417vw;
                color: rgba(0,0,0,0.8);
                font-weight: 600;
                font-style: italic;
            }
            .txt{
                font-size: 0.9375vw;
                line-height: 1.3542vw;
                color: rgba(0,0,0,0.6);
                margin-top: 1.5625vw;
            }
            .more{
                position: absolute;
                left: 0;
                bottom: 1.4583vw;
                display: flex;
                font-size: 14px;
                line-height: 1.7708vw;
                align-items: center;
                color: #E60011;
                i{
                    display: block;
                    width: 0.8333vw;
                    height: 0.8333vw;
                    background: url('../../assets/images/arrowRR.png') no-repeat;
                    background-size: cover;
                    margin-left: 0.9375vw;
                }
            }
        }

    }
    .el-pagination{
        padding: 0;
        text-align: right;
        /deep/.el-pager{
            .active{
                color: #E60011;
            }
        }
    }
}

@media screen and (max-width: 750px) {
    .new-main{
        padding: .8rem 0;
        .news-list{
            li{
                margin-bottom: .4rem;
                padding-bottom: .4rem;
            }
            a{
                display: block;
            }
            .pic{
                width: 100%;
                height: 4.5rem;
                .imgBg{
                    height: 4.5rem;
                }
            }
            .main{
                width: 100%;
                height: auto;
                .name{
                    font-size: .3rem;
                    line-height: .4rem;
                }
                .date{
                    font-size: .22rem;
                    line-height: .42rem;
                    margin-top: .1rem;
                }
                .txt{
                    font-size: .26rem;
                    line-height: .42rem;
                    margin-top: .2rem;
                }
                .more{
                    position: static;
                    font-size: .24rem;
                    margin-top: .3rem;
                }
            }
        }
    }

}
</style>